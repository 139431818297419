<template>
  <div>
    <b-modal
      id="modalAddProduct"
      hide-footer
      centered
      size="lg"
      v-model="modalAddShow"
    >
      <b-row v-if="successAdd">
        <b-col cols="12">
          <div class="my-2">
            <p class="h1 text-center">{{ $i18n.messages.ProductoAnyadido }}</p>
            <div class="mt-2 d-flex justify-content-center">
              <a
                v-if="$route.name === 'product'"
                :href="$router.resolve({ name: 'products' }).href"
              >
                <b-button variant="secondary" class="ml-2">
                  {{ $i18n.messages.SeguirComprando }}
                </b-button>
              </a>
              <b-button v-else variant="primary" @click="modalAddShow = false">
                {{ $i18n.messages.SeguirComprando }}
              </b-button>
              <a :href="$router.resolve({ name: 'cart' }).href">
                <b-button variant="secondary" class="ml-2">
                  {{ $i18n.messages.IrCarrito }}
                </b-button>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="errorAdd">
        <b-col cols="12">
          <div class="my-2">
            <p class="h1 text-center">
              {{ $i18n.messages.ErrorProductoAnyadido }}
            </p>
            <div class="mt-2 text-center">
              <b-button variant="primary" @click="modalAddShow = false">
                {{ $i18n.messages.SeguirComprando }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!successAdd && !errorAdd">
        <b-col cols="12">
          <h3>{{ product.Articulo }}</h3>
        </b-col>
        <b-col cols="12" md="6">
          <label
            >{{ $i18n.messages.Pales }} (1 {{ $i18n.messages.Pale }} =
            {{ product.CajasPalet }} {{ $i18n.messages.Cajas }})</label
          >
          <b-input-group>
            <template #prepend>
              <b-button variant="outline-primary" @click="menosPales">
                -
              </b-button>
            </template>
            <b-form-input
              v-model="quantityPales"
              type="number"
              min="0"
              readonly
            />
            <template #append>
              <b-button variant="outline-primary" @click="masPales">
                +
              </b-button>
            </template>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6">
          <label>{{ $i18n.messages.Cajas }}</label>
          <b-input-group>
            <template #prepend>
              <b-button variant="outline-primary" @click="menosCajas">
                -
              </b-button>
            </template>
            <b-form-input
              v-model="quantityCajas"
              type="number"
              min="0"
              readonly
            />
            <template #append>
              <b-button variant="outline-primary" @click="masCajas">
                +
              </b-button>
            </template>
          </b-input-group>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-progress
            animated
            :value="BarValue"
            :variant="BarVariant"
            :class="'progress-bar-' + BarVariant"
          />
          <div class="text-center">
            <p v-if="BarMessage">
              <strong>{{ BarMessage }}</strong>
            </p>
          </div>
        </b-col>
        <b-col cols="12">
          <p>
            {{ $i18n.messages.DisponibleM2 }}:
            {{
              Number(product.Disponible[0]).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
          <p>
            {{ $i18n.messages.TotalM2 }}:
            {{
              Number(total).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
          <p>
            {{ $i18n.messages.PorCajaM2 }}:
            {{
              Number(product.Metroscaja).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
          <p>
            {{ $i18n.messages.totalWeight }}:
            {{
              totalWeight.toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              })
            }}
          </p>
        </b-col>
        <b-col cols="12" class="text-center">
          <b-button variant="light" :disabled="!total" @click="formAddProduct">
            {{ $i18n.messages.Anyadir }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BInputGroup,
  BRow,
  BCol,
  VBModal,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BProgress,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantityPales: 0,
      quantityCajas: 0,
    };
  },
  created() {
    console.log(this.$route.name);
  },
  computed: {
    ...mapGetters({
      successAdd: "carts/getSuccessAdd",
      errorAdd: "carts/getErrorAdd",
    }),
    modalAddShow: {
      get() {
        return this.$store.getters["products/getShowModalAdd"];
      },
      set(val) {
        this.$store.commit("products/setShowModalAdd", val);
      },
    },
    BarValue() {
      if (this.quantityPales === 0 && this.quantityCajas === 0) {
        return 0;
      } else if (this.quantityCajas === 0) {
        return 100;
      } else {
        /*    else if (this.quantityPales === this.quantityLimitPalets) {
        return (this.quantityCajas / this.quantityLimitCajas) * 100;
      } */
        return (this.quantityCajas / this.product.CajasPalet) * 100;
      }
    },
    BarVariant() {
      if (this.BarValue === 0) {
        return "info";
      } else if (this.BarValue === 100) {
        /*  if (
          this.quantityPales === this.quantityLimitPalets &&
          this.quantityCajas > 0
        ) {
          return "danger";
        }  
        else {*/
        return "light";
        /* } */
      } else {
        return "light";
      }
    },
    BarMessage() {
      if (this.BarValue === 0) {
        return this.$i18n.messages.NecesitasCantidad;
      } else if (this.BarValue === 100) {
        if (
          this.quantityPales === this.quantityLimitPalets &&
          this.quantityCajas > 0
        ) {
          return this.$i18n.messages.NoQuedanM2;
        } else {
          return this.$i18n.messages.SinPicking;
        }
      } else {
        /*  if (this.quantityPales === this.quantityLimitPalets) {
          return this.$t("QuedanXCajas", { cajas: this.FaltanCajas });
        } else { */
        return (
          this.$t("NecesitasXCajas", { cajas: this.FaltanCajas }) +
          ". " +
          this.$i18n.messages.ConRecargoPicking
        );
        /*  } */
      }
    },
    FaltanCajas() {
      /*   if (
        this.quantityPales === this.quantityLimitPalets &&
        this.quantityLimitPalets !== 0
      ) {
        return this.quantityLimitCajas - this.quantityCajas;
      } else { */
      return this.product.CajasPalet - this.quantityCajas;
      /*  } */
    },
    total() {
      let total = 0;
      if (this.quantityCajas > 0) {
        total += this.quantityCajas * this.product.Metroscaja;
      }
      if (this.quantityPales > 0) {
        total +=
          this.quantityPales *
          this.product.CajasPalet *
          this.product.Metroscaja;
      }
      return total;
    },
    totalCajas() {
      // eslint-disable-next-line no-mixed-operators
      return this.quantityPales * this.product.CajasPalet + this.quantityCajas;
    },
    totalWeight() {
      let totalWeight = 0;
      if (this.quantityCajas > 0) {
        totalWeight +=
          this.quantityCajas * this.product.Metroscaja * this.product.PesoM2;
      }
      if (this.quantityPales > 0) {
        totalWeight +=
          this.quantityPales *
          this.product.CajasPalet *
          this.product.Metroscaja *
          this.product.PesoM2;
      }
      return totalWeight;
    },
    quantityLimitCajasTotales() {
      return Math.floor(this.product.Disponible[0] / this.product.Metroscaja);
    },
    quantityLimitPalets() {
      return Math.floor(
        this.quantityLimitCajasTotales / this.product.CajasPalet
      );
    },
    quantityLimitCajas() {
      return Math.floor(
        this.quantityLimitCajasTotales -
          this.quantityLimitPalets * this.product.CajasPalet
      );
    },
  },
  watch: {
    modalAddShow() {
      this.setSuccessAdd(false);
      this.setErrorAdd(false);
      this.quantityPales = 0;
      this.quantityCajas = 0;
    },
    quantityPales() {
      this.quantityCajas = 0;
      this.limitQuantity();
    },
    quantityCajas() {
      this.limitQuantity();
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    ...mapActions({
      addProduct: "carts/addProduct",
    }),
    ...mapMutations({
      setSuccessAdd: "carts/setSuccessAdd",
      setErrorAdd: "carts/setErrorAdd",
    }),
    limitQuantity() {
      if (this.quantityPales < 0) {
        this.quantityPales = 0;
      }
      if (this.quantityCajas < 0) {
        this.quantityCajas = 0;
      }
      if (
        this.quantityCajas >= this.product.CajasPalet &&
        this.product.CajasPalet !== 0
      ) {
        this.quantityCajas = 0;
        this.quantityPales++;
      }

      /*  if (this.quantityPales > this.quantityLimitPalets) {
        this.quantityPales = this.quantityLimitPalets;
        this.quantityCajas = this.quantityLimitCajas;
      } else if (
        this.quantityPales === this.quantityLimitPalets &&
        this.quantityCajas > this.quantityLimitCajas
      ) {
        this.quantityCajas--;
      } */
    },
    menosPales() {
      this.quantityPales--;
    },
    masPales() {
      this.quantityPales++;
    },
    menosCajas() {
      this.quantityCajas--;
    },
    masCajas() {
      this.quantityCajas++;
    },
    formAddProduct() {
      let valuePicking = 1;
      var pesototal = this.totalWeight;
      if (this.quantityCajas === 0) {
        valuePicking = 0;
      }
      var prodToAdd = {
        refean: this.product.Ean13[0],
        cantped: this.totalCajas,
        umedida: "M2",
        picking: valuePicking,
        cajasTotales: this.quantityCajas,
        cajasPalet: this.product.CajasPalet,
        metrosCaja: this.product.Metroscaja,
        peso: pesototal,
      };
      console.log(prodToAdd);
      this.addProduct(prodToAdd);
    },
  },
};
</script>
